"use client";

import Script from "next/script";

const CommonFacebookPixelScript = ({
  FACEBOOK_PIXEL_CODE,
}: {
  FACEBOOK_PIXEL_CODE: number;
}) => {
  return (
    <>
      <Script id="facebook-pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${FACEBOOK_PIXEL_CODE}');
        fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          alt="facebook-pixel"
          src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_CODE}&ev=PageView
      &noscript=1`}
        />
      </noscript>
    </>
  );
};

export default CommonFacebookPixelScript;
